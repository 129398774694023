import * as React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { usePrevious } from '../../hooks/usePrevious';
import {
  coalesceDataFields,
  getApplicationData,
  postApplicationData,
} from '../API/client';
import {
  clearQueryParams,
  convertQueryParamsToRequestFieldsCanada,
  getQueryParamsCanada,
  getSessionId,
  redirectOnReloadIsEnabled,
  storeSessionId,
} from '../API/queryParamsToRequest';
import { convertRequestFieldsToState } from '../API/requestToState';
import {
  ApplicationDataFields,
  ERROR,
  FullRequestDataFields,
  LOADING,
} from '../API/types';
import { ScreenOrder, canadaScreenOrder } from '../Config/flows';
import Loading from '../GenericComponents/Loading';
import { InitialStateType } from '../initialState';
import { useAppDispatch } from '../reduxStore';
import {
  selectAllApplicationFields,
  selectGetApplicationFieldsStatus,
  selectIsFlowCompleted,
  selectMatchingStateForConfig,
  selectPostApplicationFieldsStatus,
  setFlowCompleted,
  updateEntireApplicationState,
} from '../slices/agentApplicationSlice';
import { getNextIndex, getNextIndexHelper } from '../utils';
import { ContentGenerator } from './ContentGenerator';

export interface ViewedNewApplicationFields {
  sessionStatus: 'New';
}
export interface ViewedIncompleteApplicationFields {
  sessionStatus: 'Incomplete';
  previousStepIndex: number;
}
export interface ViewedCompletedApplicationFields {
  sessionStatus: 'Completed';
}

export type ApplicationStatusInfoFields =
  | ViewedNewApplicationFields
  | ViewedIncompleteApplicationFields
  | ViewedCompletedApplicationFields;

const CanadaFlow: React.FC = () => {
  // Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const indexQueryParam = useParams().index || '0';
  const initialSessionId = React.useRef(getSessionId()).current;
  const savedApplicationData = React.useRef({} as ApplicationDataFields);
  const [isInitialized, setIsInitialized] = React.useState(false);

  // Derived / Selectors
  const isFlowCompleted = useSelector(selectIsFlowCompleted());
  const postApplicationFieldStatus = useSelector(
    selectPostApplicationFieldsStatus()
  );
  const getApplicationFieldStatus = useSelector(
    selectGetApplicationFieldsStatus()
  );
  const pageIndex = parseInt(indexQueryParam, 10) || 0;
  const queryParams = getQueryParamsCanada();
  const keys = Object.keys(canadaScreenOrder) as (keyof ScreenOrder)[];
  const stepName = keys[pageIndex] || keys[0];
  const config = canadaScreenOrder[stepName];
  const matchingState = useSelector(selectMatchingStateForConfig(config));
  const entireState = useSelector(selectAllApplicationFields());
  const showLoadingScreen = getApplicationFieldStatus === LOADING;
  const isLoading =
    postApplicationFieldStatus === LOADING ||
    getApplicationFieldStatus === LOADING;
  const isError =
    postApplicationFieldStatus === ERROR || getApplicationFieldStatus === ERROR;

  // Funcs
  const jumpToPageWithHistory = (toIndex: number, state: InitialStateType) => {
    let idx = 0;
    while (idx !== toIndex) {
      idx = getNextIndexHelper(idx, state, canadaScreenOrder, keys);
      if (redirectOnReloadIsEnabled()) {
        navigate(`/ca/${idx}`);
      }
    }
  };

  const acquireSessionidFromQueryParamData = async (
    savedApplicationData: ApplicationDataFields
  ): Promise<string | null> => {
    const response = await dispatch(
      postApplicationData({
        state: entireState,
        savedApplicationData: savedApplicationData,
        pageIndex: pageIndex,
        completed: config.isFinalStep || false,
        sessionId: uuidv4(),
        country: 'CANADA',
      })
    )
      .unwrap()
      .catch(() => null);
    return response?.sessionId ?? null;
  };

  const getExistingApplicationData = async (): Promise<
    | ({
        queryParamData: ApplicationDataFields;
        sessionId: string;
      } & { applicationStatus: ApplicationStatusInfoFields })
    | null
  > => {
    if (queryParams) {
      const queryParamData =
        convertQueryParamsToRequestFieldsCanada(queryParams);
      const maybeSessionId = await acquireSessionidFromQueryParamData(
        queryParamData
      );

      return maybeSessionId
        ? {
            queryParamData,
            sessionId: maybeSessionId,
            applicationStatus: { sessionStatus: 'New' },
          }
        : null;
    }

    if (initialSessionId) {
      const previouslySavedApplication = await dispatch(
        getApplicationData(initialSessionId)
      )
        .unwrap()
        .catch();

      if (previouslySavedApplication.completed) {
        dispatch(setFlowCompleted(true));
        return {
          queryParamData: previouslySavedApplication.data,
          sessionId: initialSessionId,
          applicationStatus: { sessionStatus: 'Completed' },
        };
      }

      if (!previouslySavedApplication) {
        return null;
      }

      return {
        queryParamData: previouslySavedApplication.data,
        sessionId: initialSessionId,
        applicationStatus: {
          sessionStatus: 'Incomplete',
          previousStepIndex: previouslySavedApplication.stepNo,
        },
      };
    }

    return null;
  };

  const previousIsFinalStep = usePrevious(config.isFinalStep);
  const onContinue = async () => {
    if (savedApplicationData.current) {
      let currentState = entireState;
      if (config.resetState) {
        const newState = config.resetState(currentState);
        dispatch(updateEntireApplicationState(newState));
        currentState = newState;
      }

      if (config.resetApplicationData) {
        savedApplicationData.current = config.resetApplicationData(
          savedApplicationData.current
        );
      }

      const response = await dispatch(
        postApplicationData({
          state: currentState,
          savedApplicationData: savedApplicationData.current,
          pageIndex: pageIndex,
          completed: config.isFinalStep || false,
          sessionId: getSessionId() || uuidv4(),
          country: 'CANADA',
        })
      ).unwrap();

      savedApplicationData.current = coalesceDataFields(
        currentState,
        savedApplicationData.current
      );

      if (response?.sessionId) {
        storeSessionId(response.sessionId);

        if (!isInitialized) {
          setIsInitialized(true);
        }
      }

      if (previousIsFinalStep && config.isFinalStep) {
        dispatch(setFlowCompleted(true));
      }

      const newIndex = getNextIndex(
        pageIndex,
        canadaScreenOrder,
        config,
        currentState,
        matchingState
      );

      navigate(`/ca/${newIndex}${window.location.search}`);
    }
  };

  // Effects
  React.useEffect(() => {
    if (!queryParams && redirectOnReloadIsEnabled()) {
      navigate('/ca/0');
    }
  }, []);

  React.useEffect(() => {
    if (isFlowCompleted && redirectOnReloadIsEnabled()) {
      const lastScreenIndex = Object.keys(canadaScreenOrder).length - 1;
      navigate(`/ca/${lastScreenIndex}${window.location.search}`);
    }
  }, [isFlowCompleted, pageIndex, config.id]);

  React.useEffect(() => {
    getExistingApplicationData()
      .then(existingApplicationData => {
        if (existingApplicationData) {
          savedApplicationData.current = existingApplicationData.queryParamData;
          storeSessionId(existingApplicationData.sessionId);
          setIsInitialized(true);

          if (
            'previousStepIndex' in existingApplicationData.applicationStatus &&
            existingApplicationData.applicationStatus.sessionStatus ===
              'Incomplete'
          ) {
            const newState = convertRequestFieldsToState({
              ...existingApplicationData.queryParamData,
            } as FullRequestDataFields);

            jumpToPageWithHistory(
              getNextIndexHelper(
                existingApplicationData.applicationStatus.previousStepIndex,
                newState,
                canadaScreenOrder,
                keys
              ),
              newState
            );

            dispatch(updateEntireApplicationState(newState));
          } else {
            if (redirectOnReloadIsEnabled()) {
              navigate('/ca/0');
            }
          }
        } else {
          if (redirectOnReloadIsEnabled()) {
            navigate('/ca/0');
          }
        }
        clearQueryParams();
      })
      .catch(() => {
        navigate('/');
      });
  }, []);

  if (showLoadingScreen) {
    return <Loading />;
  }

  return (
    <ContentGenerator
      screens={canadaScreenOrder}
      pageIndex={pageIndex}
      onContinue={onContinue}
      isLoading={isLoading}
      isError={isError}
      testId={'CA'}
    />
  );
};

export default CanadaFlow;
